import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Card, CardBody } from "reactstrap"
import { Link } from "gatsby"
import Image from ".././image"

const AllCategories = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            filter: { fileAbsolutePath: { glob: "**/src/markdown-pages/*.md" } }
            sort: { order: ASC, fields: [frontmatter___categoryName] }
          ) {
            edges {
              node {
                id
                frontmatter {
                  title
                  path
                  articleHeading
                  categoryName
                  categoryID
                  image
                }
                html
              }
            }
          }
        }
      `}
      render={data => (
        <div className="container all-categories my-5">
          <div className="row">
            {data.allMarkdownRemark.edges.map(e => (
              <div className="col card-container" key={e.node.id}>
                <Link to={e.node.frontmatter.path}>
                  <div>
                    <Card
                      size="sm"
                      className="cat-card-list"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <CardBody className="CardBody-image">
                        <Image
                          src={e.node.frontmatter.image}
                          className="card-image card-img-categories"
                        />
                      </CardBody>
                      <p className="card-cat-title">
                        {e.node.frontmatter.categoryName}
                      </p>
                    </Card>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    />
  )
}

export default AllCategories
